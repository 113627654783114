










































































































































































import { COMPANY_TABS } from '@/models/Company';
import { Component, Vue, Prop } from 'vue-property-decorator'
import { use } from 'vue/types/umd';
import SelectDropdownLocation from './SelectDropdownLocation.vue';

@Component({
  components: {
    SelectDropdownLocation,
  },
})
export default class CompanyItem extends Vue {
  @Prop() item!: any
  public imageDefault = require('@/assets/images/image-default.png')

  goToDetail() {
    this.$router.push({
      name: 'company-detail',
      params: { companyId: this.item.id }
    })
  }

  scrollToBenefits() {
    this.$router.push({
      name: 'company-detail',
      params: { 
        companyId: this.item.id,
        selectTab: COMPANY_TABS.BENEFITS as any
        }
    })
  }
  
  public arrayIndustry: any = [];
  public industry: any = ''

  formatNumberEmployee(numberEmployee: any) {
    return new Intl.NumberFormat().format(numberEmployee)
  }

  joinIndustry(industryArray: any) {
    return industryArray.map((item: any) => item.name).join(' • ')
  }

  checkPlan(plan: string) {
    return plan != 'plan_free' ? true : false
  }
}
