











































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class SelectDropdownCompany extends Vue {
  @Prop() public title!: string
  @Prop() public listOption!: any
  @Prop() public defaultValue!: any

  public selectedValue: any[] = []

  created() {
    this.selectedValue = this.defaultValue
  }

  // emit data to update new value
  @Watch('selectedValue')
  watchValueChange() {
    // if (this.selectedValue.length) {
    //   this.$emit('onValueChange', this.selectedValue)
    // }
    this.$emit('onValueChange', this.selectedValue)
  }

  // Trigger clear all filter
  @Watch('defaultValue')
  watchDefaultValueChange() {
    if (!this.defaultValue.length) {
      this.selectedValue = this.defaultValue
    }
  }
}
