













































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import SelectDropdownCompany from './SelectDropdownCompany.vue'
import CompanyItem from './CompanyItem.vue'
import { listData, benefitTagsList } from './mockData'
import CompanyService from '@/services/CompanyService'
import { NameSearchCompany } from "@/models/Setting/Enum"
import { Area } from '@/models/GroupTag/area'
import GroupTagService from '@/services/GroupTagService'
import { GroupTagTarget } from '@/models/GroupTag/target'
// import store from '@/store'
// import FilterSearchCompany from '@/store/modules/FilterSearchCompany'
// import { getModule } from 'vuex-module-decorators'

// const FilterModule = getModule(FilterSearchCompany, store)


@Component({ components: { SelectDropdownCompany, CompanyItem } })
export default class CompanyList extends Vue {
  // public searchFilter = {
  //   industry: this.$store.state.filterSearchCompany.filter_search.industry,
  //   dei: this.$store.state.filterSearchCompany.filter_search.dei,
  //   perk: this.$store.state.filterSearchCompany.filter_search.perk,
  // }
  // selected value
  public selectedLocations: number[] = []
  public selectedIndustrys: number[] = []
  public selectedDels: number[] = []
  public selectedPerks: number[] = []

  // list option
  public locationOptions: any[] = []
  public industryOptions: any[] = []
  public deiOptions: any[] = []
  public perkOptions: any[] = []
  public listCompany: any[] = []
  public listLocations: any[] = []
  public listBenefitTags: any[] = []
  public listIndustryTags: any[] = []
  public listDeiTags: any[] = []
  private loading: boolean = true
  public valueDei: any[] = []
  public page: number = 1
  public limit: number = 20
  private totalData: number = 1
  private listArea: any[] = []
  private groupTagArea: Area[] = []
  private targetSearch: GroupTagTarget[] = []
  private selectedValueTargetArea: any[] = []
  public selectedValue: any = ''
  public selectedName: any = ''

  private topic: any = {
    nationwide_id: [],
    province_id: [],
    district_id: [],
  };

  @Watch("selectedIndustrys")
  watchIndustryChange() {
    this.valueDei = this.selectedIndustrys;
  }

  @Watch('selectedValueTargetArea')
  watchValueChange() {
    this.$emit('onValueChange', this.selectedValueTargetArea)
  }

  @Watch('this.topic.nationwide_id')
  watchValueNationWideChange() {
    this.$emit('onValueChange', this.topic.nationwide_id)
  }

  @Watch('this.topic.province_id')
  watchValueProvinceChange() {
    this.$emit('onValueChange', this.topic.province_id)
  }

  @Watch('this.topic.district_id')
  watchValueDistrictChange() {
    this.$emit('onValueChange', this.topic.district_id)
  }

  // @Watch('$store.state.filterSearchCompany.filter_search', {
  //   deep: true
  // })
  // watchFilterChange() {
  //   const query = {} as any;
  //     if (this.$store.state.filterSearchCompany.filter_search.dei.length) {
  //       query.dei = this.$store.state.filterSearchCompany.filter_search.dei.join(',')
  //     }
  //     if (this.$store.state.filterSearchCompany.filter_search.industry.length) {
  //       query.industry = this.$store.state.filterSearchCompany.filter_search.industry.join(',')
  //     }
  //     if (this.$store.state.filterSearchCompany.filter_search.perk.length) {
  //       query.perk = this.$store.state.filterSearchCompany.filter_search.perk.join(',')
  //     }
      
  //     this.$router
  //       .replace({
  //         query: query
  //       })
  //       .catch(() => {});
  // }

  created() {
    this.getListCompany()
    this.getGroupTag()
    this.getGroupTarget()
    this.getListTagArea()
    // this.watchFilterChange()
  }

  clearFilter() {
    this.selectedLocations = []
    this.selectedIndustrys = []
    this.selectedDels = []
    this.selectedPerks = []
    this.selectedValueTargetArea = []
    this.topic.nationwide_id = []
    this.topic.province_id = []
    this.topic.district_id = []
    // FilterModule.HANDLE_FILTER({
    //   dei: [],
    //   industry: [],
    //   perk: []
    // })
  }

  scrollToBenefits() {
    const element = this.$refs.benefitTags as Element
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }



  getListCompany(loadMore?: boolean) {
    const params = {
      target_area: this.selectedValueTargetArea,
      nationwide_id: this.topic.nationwide_id,
      province_id: this.topic.province_id,
      district_id: this.topic.district_id,
      industry: this.selectedIndustrys,
      dei: this.selectedDels,
      perk: this.selectedPerks,
      // page: this.page,
      limit: this.limit
    }
    CompanyService.getListCompany(params)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.data.map((item: any) => item)
          if (loadMore) {
            this.listCompany = [...this.listCompany, ...data]
          } else {
            this.listCompany = data
          }
          this.totalData = res.data.total
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => (this.loading = false))
  }
  getGroupTag() {
    CompanyService.getGroupTag()
      .then((res) => {
        if (res.status === 200) {
          this.locationOptions = res.data.location
          this.deiOptions = res.data.dei
          this.perkOptions = res.data.welfare
          this.industryOptions = res.data.practice
          this.listLocations = res.data.location
          this.listBenefitTags = res.data.welfare
          this.listDeiTags = res.data.dei
          this.listIndustryTags = res.data.practice
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  async getGroupTarget() {
    return GroupTagService.getListGroupTagTarget()
      .then((response) => {
        if (response.status === 200) {
          this.targetSearch = response.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async getListTagArea() {
    return GroupTagService.getListTagArea()
      .then((response) => {
        if (response.status === 200) {
          this.listArea = response.data.nation;
          this.groupTagArea = response.data.area;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSearch(name: string, event: any) {
    if (name === NameSearchCompany.INDUSTRY) {
      this.selectedIndustrys = event
      // this.searchFilter.industry = event
    }
    if (name === NameSearchCompany.DEI) {
      this.selectedDels = event
    //  this.searchFilter.dei = event
    }
    if (name === NameSearchCompany.PERK) {
      this.selectedPerks = event
      //  this.searchFilter.perk = event
    }
    const query = {} as any;
    if (this.selectedDels.length) {
      query.dei = this.selectedDels.join(',')
    }
    if (this.selectedIndustrys.length) {
      query.industry = this.selectedIndustrys.join(',')
    }
    if (this.selectedPerks.length) {
      query.perk = this.selectedPerks.join(',')
    }
    
    this.$router.replace({
        query: query
    }).catch(() => {});
    // console.log("this.searchFilter", this.searchFilter)
    // FilterModule.HANDLE_FILTER(this.searchFilter)
    this.getListCompany();
  }

  handleSearchValue(name: String, event: any) {
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
    });
    if (name === NameSearchCompany.INDUSTRY) {
      // if (!this.searchFilter.industry.includes(event)) {
      //   this.searchFilter.industry.push(event)
      // }
      if (!this.selectedIndustrys.includes(event)) {
        this.selectedIndustrys.pop()
        this.selectedDels.pop()
        this.selectedPerks.pop()
        this.selectedIndustrys.push(event)
      }
    }
    if (name === NameSearchCompany.DEI) {
      // if (!this.searchFilter.dei.includes(event)) {
      //   this.searchFilter.dei.push(event)
      // }
      if (!this.selectedDels.includes(event)) {
        this.selectedIndustrys.pop()
        this.selectedDels.pop()
        this.selectedPerks.pop()
        this.selectedDels.push(event)
      }
    }
    if (name === NameSearchCompany.PERK) {
      // if (!this.searchFilter.perk.includes(event)) {
      //   this.searchFilter.perk.push(event)
      // }
      if (!this.selectedPerks.includes(event)) {
        this.selectedIndustrys.pop()
        this.selectedDels.pop()
        this.selectedPerks.pop()
        this.selectedPerks.push(event)
      }
    }
    // const element = this.$refs.scrollHome as Element
    // element.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start'
    // })

    const query = {} as any;
    if (this.selectedDels.length) {
      query.dei = this.selectedDels
    }
    if (this.selectedIndustrys.length) {
      query.industry = this.selectedIndustrys
    }
    if (this.selectedPerks.length) {
      query.perk = this.selectedPerks
    }
    
    this.$router.replace({
        query: query
    }).catch(() => {});
    // FilterModule.HANDLE_FILTER(this.searchFilter)
    this.getListCompany();
    // window.scrollTo({
    //   top: 0,
    //   behavior: "auto"
    // });
  }

  searchTopic() {
    this.getListCompany();
    this.$bvModal.hide('modal-xl')
  }

  checkboxValueArea($id: string) {
    const [top1, top2, top3] = $id.split("_");

    if (Number(top3)) {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if (key.includes(`${top1}_${top2}_0`) || key.includes(`${top1}_0_0`)) {
          if ((value as any)[0]) {
            (value as any)[0].checked = true;

            if (
              (value as any)[0].getAttribute("data-checked-nationwide") &&
              !this.topic.nationwide_id.includes((value as any)[0].value)
            ) {
              this.topic.nationwide_id.push((value as any)[0].value);
            }

            if (
              (value as any)[0].getAttribute("data-checked-province") &&
              !this.topic.province_id.includes((value as any)[0].value)
            ) {
              this.topic.province_id.push((value as any)[0].value);
            }
          }
        }
      });
    } else if (Number(top2)) {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if (key.includes(`${top1}_0_0`)) {
          if ((value as any)[0]) {
            (value as any)[0].checked = true;

            if (
              (value as any)[0].getAttribute("data-checked-nationwide") &&
              !this.topic.nationwide_id.includes((value as any)[0].value)
            ) {
              this.topic.nationwide_id.push((value as any)[0].value);
            }
          }
        }

        if (key !== `${top1}_${top2}_0` && key.includes(`${top1}_${top2}_`)) {
          if ((value as any)[0]) {
            (value as any)[0].checked = false;

            if (
              (value as any)[0].getAttribute("data-checked-district") &&
              this.topic.district_id.length
            ) {
              this.topic.district_id = this.topic.district_id.filter(function (
                item: any
              ) {
                return item != (value as any)[0].value;
              });
            }
          }
        }
      });
    } else {
      Object.entries(this.$refs).forEach(([key, value]) => {
        if (key !== `${top1}_0_0` && key.includes(`${top1}_`)) {
          if ((value as any)[0]) {
            (value as any)[0].checked = false;
          }

          if (
            (value as any)[0].getAttribute("data-checked-district") &&
            this.topic.district_id.length
          ) {
            this.topic.district_id = this.topic.district_id.filter(function (
              item: any
            ) {
              return item != (value as any)[0].value;
            });
          }

          if (
            (value as any)[0].getAttribute("data-checked-province") &&
            this.topic.province_id.length
          ) {
            this.topic.province_id = this.topic.province_id.filter(function (
              item: any
            ) {
              return item != (value as any)[0].value;
            });
          }
        }
      });
    }
  }

  loadMore() {
    this.page = this.page + 1;
    this.limit = this.limit + 20;
    this.getListCompany()
  }
}
