import { AxiosResponse } from 'axios';
import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import { GroupTagTarget } from '@/models/GroupTag/target';

class GroupTagService {
  getListGroupTagTarget (): Promise<AxiosResponse<GroupTagTarget[]>> {
    return axiosApiInstance.get(`${Const.api_url}list-group-tag-target`);
  }

  getListTagArea () {
    return axiosApiInstance.get(`${Const.api_url}list-tag-area`);
  }

  getListNameActivity() {
    return axiosApiInstance.get(`${Const.api_url}list-name-activity`);
  }
}

export default new GroupTagService;