import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import axios from 'axios'

class CompanyService{
  getListCompany(params: any) {
    return axios.get(`${Const.api_url}company?limit=${params.limit}&target_area=${params.target_area}&nationwide_id=${params.nationwide_id}&province_id=${params.province_id}&district_id=${params.district_id}&industry=${params.industry}&dei=${params.dei}&perk=${params.perk}`)
  }

  getGroupTag() {
    return axios.get(Const.api_url + 'company/group-tag-region')
  }

  getCompanyDetail(company_id: any) {
    return axios.get(`${Const.api_url}company/${company_id}`)
  }

  getGroupByCompanyId(params: any) {
    return axios.get(`${Const.api_url}company/${params.company_id}/group-by-company-id?limit=${params.limit}`);
  }
}

export default new CompanyService